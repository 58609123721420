'use client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Link, useRouter } from '@lib/navigation';
import { useUserStore } from '@utils/user';
import { useLocale } from 'next-intl';

interface Props {
  labels: {
    subscribe: string;
    email: string;
  };
}

const schema = z.object({
  email: z.string().email(),
});

function NewsletterForm({ labels }: Props) {
  const userStore = useUserStore();
  return userStore.isLoggedIn ? (
    <Link href="/newsletter">
      <button className="btn color mt-4 px-4 py-3">{labels.subscribe}</button>
    </Link>
  ) : (
    <NewsletterDividerForm labels={labels} />
  );
}

const NewsletterDividerForm = ({ labels }) => {
  const { handleSubmit, register } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });
  const locale = useLocale();
  const router = useRouter();

  const onFormSubmit = handleSubmit((e) => {
    router.push(`/newsletter?email=${e.email}`, { locale });
  });

  return (
    <form className="email-form" onSubmit={onFormSubmit}>
      <input
        placeholder={labels.email}
        id="email"
        data-test="newsletter.divider.email"
        className="email-input"
        {...register('email')}
      />
      <button className="btn color email-button">{labels.subscribe}</button>
    </form>
  );
};

export default NewsletterForm;
