import root from 'window-or-global';

export const get = (key, storage = root.localStorage) => {
	try {
		let data = storage.getItem(key);
		if (data === null) {
			return undefined;
		}
		return JSON.parse(data);
	} catch (err) {
		return null;
	}
};

export const set = (key, value, storage = root.localStorage) => {
	try {
		let data = JSON.stringify(value);
		storage.setItem(key, data);
	} catch (err) {
		return null;
	}
};

export const clear = (key, storage = root.localStorage) => {
	try {
		storage.removeItem(key);
	} catch (err) {
		return null;
	}
};
