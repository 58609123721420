'use client';
import { useLocale } from 'next-intl';
import { useState, useEffect } from 'react';
import Slider from 'react-slick';
import useSWR from 'swr';

import { CoverPhoto } from '@components/Article';
import Title from '@components/Title/Title';
import { fetcher } from '@lib/api/fetcher';
import { Link } from '@lib/navigation';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SectionDivider = () => {
  const lang = useLocale();
  const { data } = useSWR<any>(`/posts/panel/wall/${lang}`, fetcher, {
    refreshInterval: 20000,
  });
  const [isVisible, setVisible] = useState<boolean>(false);
  const [sectionData, setSectionData] = useState<any>();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const renderList = (dataObj, isSlider = false) => {
    const tData = dataObj;

    const getArt = (id: number) => ({
      title: tData[`title${id}`],
      url: tData[`url${id}`],
      image: tData[`image${id}`],
    });

    if (isSlider) {
      return [1, 2, 3].map((item) => {
        const article = getArt(item);
        return (
          <div
            className="section-article"
            key={article.title}
            data-test={'section.divider.slick' + item}
          >
            {artCover(article, item, true)}
          </div>
        );
      });
    }

    return [1, 2, 3].map((item) => {
      const article = getArt(item);
      return (
        <div
          className={
            'col-lg-7 col-md-8 section-article ' +
            (item !== 1 ? 'offset-lg-1' : '')
          }
          key={article.title}
          data-test={'section.divider.' + item}
        >
          {artCover(article, item)}
        </div>
      );
    });
  };

  useEffect(() => {
    const specialDataRest = data?.special;

    if (
      specialDataRest === undefined ||
      specialDataRest === null ||
      specialDataRest?.id === undefined
    )
      return;

    setVisible(true);
    setSectionData(specialDataRest);
  }, [data]);

  const artCover = (article, id, isSlick = false) => {
    const componentItem = (
      <div className={(article?.url || '').length === 0 ? '' : 'pointer'}>
        <CoverPhoto
          imgUrl={article?.image}
          isZoomable
          postType="post"
          url={article?.url}
          isDarken={false}
        />
        <div
          className="section-article_title"
          data-test={`section.divider.${isSlick && 'slick.'}title${id}`}
        >
          {article.title}
        </div>
      </div>
    );

    return (article?.url || '').length === 0 ? (
      componentItem
    ) : (
      <Link href={article?.url}>{componentItem}</Link>
    );
  };

  if (!isVisible) return null;

  return (
    <div className="pb-md-5 pb-2">
      <div
        className="section-divider px-md-0 px-3"
        style={
          sectionData?.extra?.img
            ? {
              background: `url(${sectionData?.extra?.img})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }
            : {}
        }
        data-test="section.divider"
      >
        <div className="container">
          <Title
            text={sectionData?.title}
            isBorder={false}
            className="section-title"
            isLight
          />
          <div className="row d-md-flex d-none">
            {renderList(sectionData?.data, false)}
          </div>
          <div className="row d-md-none d-block section-article--slick">
            <Slider {...settings}>{renderList(sectionData?.data, true)}</Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionDivider;
