export const getIPressoAgreements = (agreements: {
  marketingAgreement?: boolean;
  commercialInside?: boolean;
  commercialOutside?: boolean;
}) => {
  const returnObject = {
    marketing: false,
    commercialInside: false,
    commercialOutside: false,
  };
  if (agreements.marketingAgreement) {
    returnObject['marketing'] = true;
  }
  if (agreements.commercialInside) {
    returnObject['commercialInside'] = true;
  }
  if (agreements.commercialOutside) {
    returnObject['commercialOutside'] = true;
  }
  return returnObject;
};
