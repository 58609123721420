import { logger } from '@logger';
import { fetchApi } from '@utils/api/client';
import { IPRESSO_SOURCES } from '@utils/api/client/ipresso';
import { IpressoFetchResponse, IpressoPostBody } from '@utils/api/server/ipresso';
import { CategoryData } from '@utils/api/server/ipresso/map/categories';
import { getIPressoAgreements } from '@utils/ipresso';

interface IpressoRegisterAgreements {
  marketingAgreement: boolean;
  commercialInside?: boolean;
  commercialOutside?: boolean;
  locale: string;
  email: string;
  categoryData?: CategoryData;
  tags?: string[];
}

export const submitIpressoAgreements = async ({
  marketingAgreement,
  commercialInside,
  commercialOutside,
  locale,
  email,
  categoryData,
  tags,
}: IpressoRegisterAgreements) => {
  try {
    const agreements = getIPressoAgreements({
      marketingAgreement,
      commercialInside: commercialInside || false,
      commercialOutside: commercialOutside || false,
    });

    const bodyObject: IpressoPostBody = {
      contactData: { email },
      lang: {
        agreement: locale,
      },
      agreementData: agreements,
      categoryData,
      tags,
      source: IPRESSO_SOURCES.REGISTER,
    };

    //? Ipresso Agreements
    return await fetchApi<IpressoFetchResponse>('/campaign/edit', {
      method: 'POST',
      noAuth: true,
      body: JSON.stringify(bodyObject),
    });
  } catch (e) {
    logger.error(e);
  }
};
