'use client'

import { logger } from '@logger';

declare let fbq: any;

const enableAnalytics = process.env.NEXT_PUBLIC_ANALYTICS === 'true' ? true : false;

export function pushFBPixelEvent(event: string, values?: any) {

  if (!enableAnalytics) {
    logger.debug('Analytics is disabled, to enable set environment variable NEXT_PUBLIC_ANALYTICS=true')
    return;
  }

  if (typeof fbq !== 'undefined') {
    fbq(`track`, `${event}`,
      JSON.stringify(values),
    );
  }

}
