'use client';
import { useLocale } from 'next-intl';
import { useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import { Thumbnail } from '@components/Article/atoms';
import { getAuthorLink } from '@lib/author/getters';
import { Link } from '@lib/navigation';
import { getUserName } from '@lib/user';

type Props = {
  authors: any[];
};
const AuthorsList = ({ authors }: Props) => {
  const [active, setActive] = useState<number>(0);


  if (!Array.isArray(authors))
    return <div>---</div>;
  const maxPages: number = Math.ceil(authors.length / 6);

  return (
    <div key="wrapper" className="authors-section_wrapper">
      <div key="arrows" className="author-arrows">
        <button
          className={`author-arrow ${active === 0 ? 'disabled' : ''} transform hover:scale-110 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
          aria-label="prev"
          onClick={() => setActive(active - 1)}
        >
          <IoIosArrowBack />
        </button>
        <button
          className={`author-arrow ${active + 1 >= maxPages ? 'disabled' : ''} transform hover:scale-110 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
          aria-label="next"
          onClick={() => setActive(active + 1)}
        >
          <IoIosArrowForward />
        </button>
      </div>
      {
        authors
          ?.slice(active * 6, (active + 1) * 6)
          .map((author: any, id: number) => (
            <Link href={getAuthorLink(author)} key={id}>
              <div
                className={'authors-section my-4'}
              >
                <AuthorCard key={id} author={author} keyId={id} />
              </div>
            </Link>
          ))
      }
    </div >
  );
};

const AuthorCard = ({ author, keyId = 0 }: { author: any; keyId: number }) => {
  const lang = useLocale();
  const customFieldName = lang === 'ua' ? 'userNameUk' : undefined;

  return (
    <>
      <Thumbnail
        path={author?.avatar}
        name={getUserName(author, customFieldName)}
        className=" pointer"
        size="medium"
        isZoomable
      />
      <div
        data-test={'author.card.' + keyId}
        className="authors-section_info pointer"
      >
        <div className="name">{getUserName(author, customFieldName)}</div>
        <div className="caption">{author?.userCaption}</div>
      </div>
    </>
  );
};
export default AuthorsList;

