import cn from 'classnames';
import React from 'react';

import { Link } from '@lib/navigation';

type Size = 'sm' | 'md' | 'lg' | 'xl';
type Align = 'center' | 'left' | 'right';
type Margin = 'none' | 'sm' | 'md' | 'lg';

type Props = {
  Component?: React.ElementType;
  text: string;
  className?: string;
  size?: Size;
  margin?: Margin;
  align?: Align;
  url?: string | null;
  isBorder?: boolean;
  isLight?: boolean;
};
// TODO: SEO issue with using div instead of heading tags
export default function Title({
  Component = 'h2',
  text,
  size,
  margin,
  align,
  isBorder = false,
  className,
  isLight = false,
  url = null,
}: Props) {

  const fontSize = {
    sm: 'text-[20px] leading-8',
    md: 'text-[24px] leading-9',
    lg: 'text-[30px] leading-10',
    xl: 'text-[36px] leading-10',
  }[size || 'md'];

  const textAlign = {
    center: 'text-center',
    left: 'text-left',
    right: 'text-right',
  }[align || 'left'];

  const textMargin = {
    none: '',
    sm: 'mb-4',
    md: 'mb-8',
    lg: 'mb-12',
  }[margin || 'sm'];

  return (
    <Component
      className={cn(
        'font-bold first-letter:uppercase  dark:text-white',
        className,
        fontSize,
        textAlign,
        textMargin,
        {
          'pb-1 sm:border-b sm:border-gray-200': isBorder,
          'text-white': isLight,
          'text-premium-dark': !isLight,
        },
      )}
    >
      {!url ? text : <Link href={url}>{text}</Link>}
    </Component>
  );
}
