export type newslettersCountries = 'pl' | 'en' | 'de' | 'ua';

export enum NewslettersExist {
  pl,
  en,
  de,
  ua,
  lt,
  ru,
}
